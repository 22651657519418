import { ErrorMessages } from '../types/ErrorMessages';

export default function MapErrorMessage(
    errorCode: ErrorMessages = ErrorMessages.GENERIC_ERROR,
) {
    const errorMessages = {
        GENERIC_ERROR: 'Something went wrong. Please try again!',
        USER_ALREADY_EXIST: 'User already exists!',
        INSUFFICIENT_ROLE: 'insufficient Role!',
        USER_DOES_NOT_EXIST: 'User does not exists!',
        INVALID_CREDENTIALS: 'Email and Password do not matched!',
        ORDER_DOES_NOT_EXIST: 'Order does not exists!',
        INCORRECT_PASSWORD: 'The password is incorrect!',
        INVALID_ASSIGNEE_ROLE: 'Invalid assignee Role!',
        USER_DEACTIVATED: 'User is deactivated!',
        ORDER_ALREADY_EXIST: 'Order already exists!',
        PASSWORD_REQUIRED: 'Password required!',
        CANNOT_REASSIGN_RIDER: 'Order already has an assigned rider.',
    };

    return errorMessages[errorCode];
}
