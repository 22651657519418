export enum ErrorMessages {
    GENERIC_ERROR = 'GENERIC_ERROR',
    USER_ALREADY_EXIST = 'USER_ALREADY_EXIST',
    INSUFFICIENT_ROLE = 'INSUFFICIENT_ROLE',
    USER_DOES_NOT_EXIST = 'USER_DOES_NOT_EXIST',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    ORDER_DOES_NOT_EXIST = 'ORDER_DOES_NOT_EXIST',
    INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
    INVALID_ASSIGNEE_ROLE = 'INVALID_ASSIGNEE_ROLE',
}
